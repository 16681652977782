
// Formulator submits a form in the background when inputs change, and gives the
// resulting HTML to one or more callbacks as a document fragment. You can also
// fire callbacks when a change is first detected.
//
// You can use this to e.g. refresh a basket section of the page as the
// quantities or item types change.
//
// First argument is the <form> element, second argument is an array of regexes
// to match input names against, third argument is a map of values to override.
// Formulator won't submit if none of the regexes match the changing input name.
//
// var formulator = new globals.Formulator(
// document.querySelector( '.some-class' ),
//   [
//     /^product\[\d+\]$/,
//   ],
//   {
//     redirect: '{{ ''|hash }}' // Always come back to the same page.
//   }
// );
// formulator.addStartCallback( ( input ) => {
//   ...
// } );
// formulator.addDoneCallback( ( fragment ) => {
//   ...
// } );
//
// REMEMBER: This is submitting your form in the background even if valid! Make
// sure your controllers can handle that, like you're not creating tons of
// phantom data silently or preventing user submission of the form or something.

export class Formulator {
	constructor( form, input_name_regexes, value_overrides ) {
		this.form = form;
		this.input_name_regexes = input_name_regexes;
		this.value_overrides = value_overrides;
		this.start_callbacks = [];
		this.done_callbacks = [];
		const handler = this.handleUpdate();
		this.form.addEventListener( 'change', handler );
	}

	// These callbacks are fired as soon as a regex-matched input change event
	// occurs.
	addStartCallback( callback ) {
		this.start_callbacks.push( callback );
	}

	// These callbacks are fired once the form submission fetch call has
	// returned.
	addDoneCallback( callback ) {
		this.done_callbacks.push( callback );
	}

	handleUpdate() {
		return ( event ) => {
			let matches_a_regex = false;
			for ( let i = 0; i < this.input_name_regexes.length; i++ ) {
				if ( this.input_name_regexes[i].test( event.target.name ) ) {
					matches_a_regex = true;
					break;
				}
			}
			if ( !matches_a_regex ) {
				return;
			}
			event.preventDefault();
			this.doUpdate( event.target );
		};
	}

	doUpdate( input ) {
		for ( let i = 0; i < this.start_callbacks.length; i++ ) {
			this.start_callbacks[i]( input );
		}
		let action;
		if ( typeof this.form.action === 'string' ) {
			action = this.form.action;
		} else {
			action = '';
		}
		const form_data = new FormData( this.form );
		for ( const name in this.value_overrides ) {
			form_data.set( name, this.value_overrides[name] );
		}
		fetch( action, {
			method: this.form.method,
			body: form_data,
		} )
			.then( response => response.text() )
			.then( data => {
				const frag = ( new DOMParser() ).parseFromString( data, 'text/html' );
				for ( let i = 0; i < this.done_callbacks.length; i++ ) {
					this.done_callbacks[i]( frag );
				}
			} );
	}
}
