
export class CookieConsentManager {

	constructor(
		tag_managers,
		categories,
		providers,
		provider_hash,
		use_cookie_banner = false
	) {
		this.tag_managers = tag_managers;
		this.categories = categories;
		this.providers = providers;
		this.provider_hash = provider_hash;

		window.addEventListener( 'click', ( event ) => {
			if ( 'cookieManagerAcceptAll' in event.target.dataset ) {
				event.preventDefault();
				this.savePreferences( Object.keys( this.providers ) );
				this.closeCookieManagerUi();
				this.closeCookieBannerUi();
				this.applySavedPreferences();
				this.setInputsFromPreferences();
				this.hideButtons();
				this.reloadPage();
				return;
			}
			if ( 'cookieManagerDenyAll' in event.target.dataset ) {
				event.preventDefault();
				this.savePreferences( [] );
				this.closeCookieManagerUi();
				this.closeCookieBannerUi();
				this.applySavedPreferences();
				this.setInputsFromPreferences();
				this.hideButtons();
				this.reloadPage();
				return;
			}
			if ( 'cookieManagerAcceptAllCategory' in event.target.dataset ) {
				event.preventDefault();
				const prefs = ( this.getSavedPreferences() || [] ).concat(
					this.getProviderKeysInCategory(
						event.target.dataset.cookieManagerAcceptAllCategory
					)
				).filter( ( value, index, self ) => {
					// Filter out non-unique items.
					return self.indexOf( value ) === index;
				} );
				this.savePreferences( prefs );
				this.applySavedPreferences();
				this.setInputsFromPreferences();
				this.hideButtons();
				this.reloadPage();
				return;
			}
			if ( 'cookieManagerDenyAllCategory' in event.target.dataset ) {
				event.preventDefault();
				const category_prefs = this.getProviderKeysInCategory(
					event.target.dataset.cookieManagerDenyAllCategory
				);
				const prefs = ( this.getSavedPreferences() || [] ).filter(
					( value ) => {
						return category_prefs.indexOf( value ) === -1;
					}
				);
				this.savePreferences( prefs );
				this.applySavedPreferences();
				this.setInputsFromPreferences();
				this.hideButtons();
				this.reloadPage();
				return;
			}
			if ( 'cookieManagerAcceptProvider' in event.target.dataset ) {
				event.preventDefault();
				const prefs = ( this.getSavedPreferences() || [] ).concat(
					[event.target.dataset.cookieManagerAcceptProvider]
				).filter( ( value, index, self ) => {
					// Filter out non-unique items.
					return self.indexOf( value ) === index;
				} );
				this.savePreferences( prefs );
				this.applySavedPreferences();
				this.setInputsFromPreferences();
				this.hideButtons();
				this.reloadPage();
				return;
			}
			if ( 'cookieManagerSaveChanges' in event.target.dataset ) {
				event.preventDefault();
				this.savePreferences( this.getPreferencesFromInputs() );
				this.closeCookieManagerUi();
				this.applySavedPreferences();
				this.hideButtons();
				this.reloadPage();
				return;
			}
			if ( 'cookieManagerOpenUi' in event.target.dataset ) {
				event.preventDefault();
				this.openCookieManagerUi();
				return;
			}
		} );

		window.addEventListener( 'change', ( event ) => {
			if (
				'cookieManagerCategoryPreference' in event.target.dataset
				|| 'cookieManagerProviderPreference' in event.target.dataset
			) {
				this.showButtons();
				this.forceSaveChanges();
			}
		} );

		const cookie_preferences = this.getSavedPreferences();
		if ( cookie_preferences === null ) {
			this.showButtons();
			if ( use_cookie_banner ) {
				this.openCookieBannerUi();
			} else {
				this.openCookieManagerUi();
			}
			return;
		}

		this.applySavedPreferences();
		this.setInputsFromPreferences();
	}

	savePreferences( agreed_providers ) {
		const date = new Date();
		date.setTime( date.getTime() + ( 90 * 24 * 60 * 60 * 1000 ) );

		const validated_agreed_providers = [];
		for ( let i = 0; i < agreed_providers.length; i++ ) {
			if ( !Object.prototype.hasOwnProperty.call(
				this.providers,
				agreed_providers[i]
			) ) {
				continue;
			}
			if ( this.getProviderIsMandatory( agreed_providers[i] ) ) {
				continue;
			}
			validated_agreed_providers.push( agreed_providers[i] );
		}

		this.setCookie(
			'ten4_cookie_consent',
			this.provider_hash + '|' + validated_agreed_providers.join( ',' ),
			date
		);
	}

	getSavedPreferences() {
		const consent_cookie = this.getCookie( 'ten4_cookie_consent' );
		if ( consent_cookie !== '' ) {
			const [hash, agreed_providers] = consent_cookie.split( '|' );
			if ( hash === this.provider_hash ) {
				if ( agreed_providers === '' ) {
					return [];
				}
				return agreed_providers.split( ',' );
			}
			return null;
		}
		return null;
	}

	getPreferencesFromInputs() {
		let preferences = [];
		const category_pref_inputs = document.querySelectorAll( '[data-cookie-manager-category-preference]' );
		for ( let i = 0; i < category_pref_inputs.length; i++ ) {
			if ( !category_pref_inputs[i].checked ) {
				continue;
			}
			preferences = preferences.concat(
				this.getProviderKeysInCategory(
					category_pref_inputs[i].dataset.cookieManagerCategoryPreference
				)
			);
		}
		const provider_pref_inputs = document.querySelectorAll( '[data-cookie-manager-provider-preference]' );
		for ( let i = 0; i < provider_pref_inputs.length; i++ ) {
			if ( !provider_pref_inputs[i].checked ) {
				continue;
			}
			preferences.push( provider_pref_inputs[i].dataset.cookieManagerProviderPreference );
		}

		return preferences.filter( ( value, index, self ) => {
			// Filter out non-unique items.
			return self.indexOf( value ) === index;
		} );
	}

	setInputsFromPreferences() {
		const preferences = this.getSavedPreferences();
		const inputs = document.querySelectorAll( '[data-cookie-manager-provider-preference]' );
		let updated = false;
		for ( let i = 0; i < inputs.length; i++ ) {
			if (
				preferences.indexOf(
					inputs[i].dataset.cookieManagerProviderPreference
				) === -1
			) {
				inputs[i].checked = false;
				continue;
			}
			inputs[i].checked = true;
			updated = true;
		}
		if ( updated ) {
			this.forceSaveChanges();
		}
	}

	applySavedPreferences() {
		const agreed_provider_keys = this.getSavedPreferences();

		// Set global variable for e.g. tag managers to use.
		window.ten4_cookie_consent = agreed_provider_keys;

		this.injectHtml( this.tag_managers );

		if ( agreed_provider_keys === null ) {
			return;
		}
		const agreed_providers = {};
		const denied_providers = {};
		outer: for ( const provider in this.providers ) {
			if (
				agreed_provider_keys.indexOf( provider ) === -1
			) {
				denied_providers[provider] = this.providers[provider];
				continue outer;
			}
			agreed_providers[provider] = this.providers[provider];
		}

		this.removeProviderCookies( denied_providers );
		this.injectHtml( Object.values( agreed_providers ) );
		this.renderProviderWrappers( agreed_providers );
	}

	reloadPage() {
		window.location.reload();
	}

	getProviderIsMandatory( provider_key ) {
		const categories = this.providers[provider_key].categories;
		for ( let i = 0; i < categories.length; i++ ) {
			if ( !this.categories[categories[i]].mandatory ) {
				return false;
			}
		}
		return true;
	}

	getProviderKeysInCategory( category_key ) {
		const keys = [];
		for ( const provider in this.providers ) {
			if (
				this.providers[provider].categories.indexOf(
					category_key
				) === -1
			) {
				continue;
			}
			keys.push( provider );
		}
		return keys;
	}

	openCookieManagerUi() {
		this.closeCookieBannerUi();
		document.body.classList.add( 'cookie-manager-open' );
	}

	closeCookieManagerUi() {
		document.body.classList.remove( 'cookie-manager-open' );
	}

	openCookieBannerUi() {
		document.body.classList.add( 'cookie-banner-open' );
	}

	closeCookieBannerUi() {
		document.body.classList.remove( 'cookie-banner-open' );
	}

	showButtons() {
		document.body.classList.add( 'cookie-manager-buttons-visible' );
	}

	hideButtons() {
		document.body.classList.remove( 'cookie-manager-buttons-visible' );
	}

	forceSaveChanges() {
		document.body.classList.add( 'cookie-manager-save-changes' );
	}

	removeProviderCookies( providers ) {
		for ( const provider in providers ) {
			if ( this.getProviderIsMandatory( provider ) ) {
				continue;
			}
			for ( const cookie in providers[provider].cookies_dropped ) {
				if ( cookie !== 'ten4_cookie_consent' && this.getCookie( cookie ).length > 0 ) {
					this.deleteCookie( cookie );
				}
			}
		}
	}

	injectHtml( configs ) {
		const html_to_inject = {
			head_end: '',
			body_end: '',
		};
		for ( let i = 0; i < configs.length; i++ ) {
			if ( typeof configs[i].html !== 'undefined' ) {
				const injection_point = ( configs[i].html_injection_point || 'body_end' );
				if ( !Object.prototype.hasOwnProperty.call(
					html_to_inject,
					injection_point
				) ) {
					throw new Error( 'Invalid HTML injection point.' );
				}
				html_to_inject[injection_point] += configs[i].html;
			}
		}
		if ( html_to_inject.head_end.length > 0 ) {
			this.insertHTML( document.head, html_to_inject.head_end );
		}
		if ( html_to_inject.body_end.length > 0 ) {
			this.insertHTML( document.body, html_to_inject.body_end );
		}
	}

	renderProviderWrappers( providers ) {
		const wrappers = document.querySelectorAll( '.cookie-consent-wrapper' );
		for ( let i = 0; i < wrappers.length; i++ ) {
			const provider_key = wrappers[i].getAttribute( 'data-provider-key' );
			if ( !Object.prototype.hasOwnProperty.call(
				providers,
				provider_key
			) ) {
				continue;
			}
			const wrapper_element = wrappers[i].querySelector(
				'.cookie-consent-wrapper__unblocked'
			);
			if ( !wrapper_element ) {
				// Already unblocked, probably.
				continue;
			}
			const wrapper_content = wrapper_element.textContent;
			this.insertHTML( wrappers[i], wrapper_content, true );
			wrappers[i].classList.add( 'cookie-consent-wrapper--unblocked' );
		}
	}

	insertHTML( parent_node, html_string, replace = false ) {
		const fragment_range = document.createRange();
		fragment_range.selectNode( parent_node );
		const fragment = fragment_range.createContextualFragment(
			html_string
		);
		if ( replace ) {
			parent_node.innerHTML = '';
		}
		parent_node.appendChild( fragment );
	}

	getCookie( name ) {
		const value='; ' + document.cookie;
		const parts=value.split( '; ' + name + '=' );
		if ( parts.length === 2 ) {
			return parts.pop().split( ';' ).shift();
		}
		return '';
	}

	setCookie( name, value, expires ) {
		document.cookie = name + '=' + value + ';path=/;expires=' + expires.toGMTString() + ';SameSite=Lax;Secure';
	}

	deleteCookie( name ) {
		const domain = window.location.hostname.split( '.' );
		while ( domain.length > 1 ) {
			document.cookie = name + '=;path=/;domain=' + domain.join( '.' ) + ';expires=Thu, 01 Jan 1970 00:00:01 GMT';
			domain.shift();
		}
	}

}