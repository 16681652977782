export class HandleTabs {
	constructor( container ) {
		this.container = container;
		this.keys = {
			end: 35,
			home: 36,
			left: 37,
			up: 38,
			right: 49,
			down: 40,
		};
		// Add or subtract depending on direction
		this.direction = {
			37: -1,
			38: -1,
			39: 1,
			40: 1,
		};
		this.tablist = container.querySelectorAll( '[role="tablist"]' )[0];
		this.delay = this.determineDelay();
		this.tabs = this.container.querySelectorAll( '[role="tab"]' );
		this.panels = this.container.querySelectorAll( '[role="tabpanel"]' );

		// Add listeners
		for ( let i = 0; i < this.tabs.length; i++ ) {
			this.addListeners( i );
		}

	}

	addListeners( index ) {
		this.tabs[index].addEventListener( 'click', ( event ) => {
			this.clickListener( event );
		} );
		this.tabs[index].addEventListener( 'keydown', ( event ) => {
			this.keydownListener( event );
		} );
		this.tabs[index].addEventListener( 'keyup', ( event ) => {
			this.keyupListener( event );
		} );

		this.tabs[index].index = index;
	}

	determineDelay() {
		const has_delay = this.tablist.hasAttribute( 'data-delay' );
		let delay = 0;

		if ( has_delay ) {
			const delay_value = this.tablist.getAttribute( 'data-delay' );
			if ( delay_value ) {
				delay = delay_value;
			} else {
				delay = 300;
			}
		}

		return delay;
	}

	clickListener( event ) {
		const tab = event.target;
		this.activateTab( tab, false );
	}

	keydownListener( event ) {
		const key = event.keyCode;

		switch ( key ) {
			case this.keys.end:
				event.preventDefault();
				this.activateTab( this.tabs[tabs.length - 1] );
				break;

			case this.keys.home:
				event.preventDefault();
				this.activateTab( this.tabs[0] );
				break;

			// Up and down are in keydown because we need to prevent page scroll >:)
			case this.keys.up:
			case this.keys.down:
				this.determineOrientation( event );
				break;
		}
	}

	keyupListener( event ) {
		const key = event.keyCode;

		switch ( key ) {
			case this.keys.left:
			case this.keys.right:
				this.determineOrientation( event );
				break;
		}
	}

	focusHandler( event ) {
		const target = event.target;
		setTimeout( () => {
			this.checkTabFocus( target );
		}, this.delay );
	}

	determineOrientation( event ) {
		const key = event.keyCode;
		const vertical = this.tablist.getAttribute( 'aria-orientation' ) === 'vertical';
		let proceed = false;

		if ( vertical ) {
			if ( key === this.keys.up || key === this.keys.down ) {
				event.preventDefault();
				proceed = true;
			}
		} else {
			if ( key === this.keys.left || key === this.keys.right ) {
				proceed = true;
			}
		}

		if ( proceed ) {
			this.switchTabOnArrowPress( event );
		}
	}

	switchTabOnArrowPress( event ) {
		const pressed = event.keyCode;

		for ( let x = 0; x < this.tabs.length; x++ ) {
			this.tabs[x].addEventListener( 'focus', ( event ) => {
				this.focusHandler( event );
			} );
		}

		if ( this.direction[pressed] ) {
			const target = event.target;
			if ( target.index  !== undefined ) {
				if ( this.tabs[target.index + this.direction[pressed]] ) {
					this.tabs[target.index + this.direction[pressed]].focus();
				} else if ( pressed === this.keys.left || pressed === this.keys.up ) {
					this.focusLastTab();
				} else if ( pressed === this.keys.right || pressed === this.keys.down ) {
					this.focusFirstTab();
				}
			}
		}
	}

	activateTab( tab, set_focus ) {
		set_focus = set_focus || true;
		this.deactivateTabs();

		tab.removeAttribute( 'tabindex' );

		tab.setAttribute( 'aria-selected', true );

		const controls = tab.getAttribute( 'aria-controls' );
		document.getElementById( controls ).classList.remove( 'is-hidden' );

		if ( set_focus ) {
			tab.focus();
		}
	}

	deactivateTabs() {
		for ( let t = 0; t < this.tabs.length; t++ ) {
			this.tabs[t].setAttribute( 'tabindex', '-1' );
			this.tabs[t].setAttribute( 'aria-selected', false );
			this.tabs[t].removeEventListener( 'focus', ( event ) => {
				this.focusHandler( event );
			} );
		}

		for ( let p = 0; p < this.panels.length; p++ ) {
			this.panels[p].classList.add( 'is-hidden' );
		}
	}

	focusFirstTab() {
		this.tabs[0].focus();
	}

	focusLastTab() {
		this.tabs[this.tabs.length - 1].focus();
	}

	checkTabFocus( target ) {
		const focused = document.activeElement;

		if ( target === focused ) {
			this.activateTab( target, false );
		}
	}
}