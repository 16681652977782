
export function rebindFormElems() {
	bindAllCheckboxGroupElements();
	bindAllRadioGroupElements();
	bindAllSelectElements();
}

function bindAllCheckboxGroupElements() {

	const checkbox_elements = document.querySelectorAll( '[data-checkbox-trigger]' );
	if ( !checkbox_elements ) {
		return;
	}
	bindHiddenCheckboxGroupElements( checkbox_elements );

};

function bindAllRadioGroupElements() {

	const radio_elements = document.querySelectorAll( '[data-radio-trigger]' );
	if ( !radio_elements ) {
		return;
	}
	bindHiddenRadioGroupElements( radio_elements );

};

function bindAllSelectElements() {

	const select_elements = document.querySelectorAll( '[data-select-trigger]' );
	if ( !select_elements ) {
		return;
	}
	bindHiddenSelectElements( select_elements );

};

function bindHiddenCheckboxGroupElements( elements ) {
	for ( let k = 0; k < elements.length; k++ ) {
		elements[k].addEventListener( 'change', revealCollapsedElementWithCheckbox( elements[k] ) );
		if ( elements[k].checked ) {
			revealCollapsedElementWithCheckbox( elements[k] )();
		}
	}
};

function bindHiddenRadioGroupElements( radio_elements ) {
	for ( let i = 0; i < radio_elements.length; i++ ) {
		const items = radio_elements[i].querySelectorAll( 'input' );
		if ( items ) {
			for ( let j = 0; j < items.length; j++ ) {
				items[j].addEventListener( 'change', revealCollapsedElementWithRadio( radio_elements[i], items[j] ) );
				if ( items[j].checked ) {
					revealCollapsedElementWithRadio( radio_elements[i], items[j] )();
				}
			}
		}
	}
};

function bindHiddenSelectElements( elements ) {
	for ( let i = 0; i < elements.length; i++ ) {
		elements[i].addEventListener( 'change', revealCollapsedElementWithSelect( elements[i] ) );
		if ( elements[i].getAttribute( 'data-child-trigger' ) === elements[i].value ) {
			revealCollapsedElementWithSelect( elements[i] )();
		}
	}
};

function revealCollapsedElementWithCheckbox( trigger ) {
	return () => {
		const elemToToggleName = trigger.getAttribute( 'data-child' );
		const elemToToggle = document.getElementById( elemToToggleName );
		if ( !elemToToggle ) {
			return;
		}
		const isReversed = trigger.getAttribute( 'data-child-trigger-reversed' );
		if ( trigger.checked ) {
			if ( isReversed ) {
				collapseItem( elemToToggle );
			} else {
				expandItem( elemToToggle );
			}
			return;
		}
		if ( isReversed ) {
			expandItem( elemToToggle );
		} else {
			collapseItem( elemToToggle );
		}
	}
};

function revealCollapsedElementWithSelect( trigger ) {
	return () => {
		const elemToToggleName = trigger.getAttribute( 'data-child' );
		const elemToToggle = document.getElementById( elemToToggleName );
		const triggerValue = trigger.getAttribute( 'data-child-trigger' );
		if ( !elemToToggle || !triggerValue ) {
			return;
		}
		if ( trigger.value == triggerValue ) {
			expandItem( elemToToggle );
			return;
		}
		collapseItem( elemToToggle );
	}
};

function revealCollapsedElementWithRadio( fieldset, childChanged ) {
	return () => {
		const elemToToggleName = fieldset.getAttribute( 'data-child' );
		const isReversed = fieldset.getAttribute( 'data-child-trigger-reversed' );
		const elemToToggle = document.getElementById( elemToToggleName );
		const triggerValue = fieldset.getAttribute( 'data-child-trigger' );
		const shipping_input = document.getElementById( 'shipping-is-billing' );
		if ( shipping_input ) {
			shipping_input.value = shipping_input.value === '1' ? '0' : '1';
		}
		if ( !elemToToggle || !triggerValue ) {
			return;
		}
		if ( childChanged.value == triggerValue ) {
			if ( isReversed ) {
				collapseItem( elemToToggle );
			} else {
				expandItem( elemToToggle );
			}
			return;
		}
		if ( isReversed ) {
			expandItem( elemToToggle );
			return;
		}
		collapseItem( elemToToggle );
	}
};

function expandItem( elem ) {
	elem.classList.remove( 'form__collapsible--collapsed' );
	elem.setAttribute( 'aria-expanded', true );
};

function collapseItem( elem ) {
	elem.classList.add( 'form__collapsible--collapsed' );
	elem.setAttribute( 'aria-expanded', false );
};